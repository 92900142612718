<template>
  <div class="container-404 flex" v-lazy-container="{ selector: 'img' }">
    <Navbar ref="navbar"/>
    <div class="self-center relative w-full flex flex-col items-center justify-center">
      <h1 class="font-raleway-extra-bold mb-20 lg:mb-6 xl:mb-0">
        404
      </h1>
      <PrimaryButton
        :text="$t('404.button').toString()"
        bg-color="bg-white"
        classes="mt-8 text-space-dark-blue !absolute bottom-0 left-1/2 transform -translate-x-1/2 -translate-y-1/3"
        @click="goBack"
      />
    </div>
  </div>
</template>

<script>
import Navbar from "@/layouts/partials/home-layout/Navbar";
import PrimaryButton from "@/components/buttons/PrimaryButton";
import {ROUTE_HOME} from "@/router";

export default {
  name: "NotFound",
  components: {PrimaryButton, Navbar},
  methods: {
    async goBack() {
      await this.$router.push(ROUTE_HOME.path);
    }
  }
};
</script>

<style scoped>
.container-404 {
  background-image: url("~@/assets/images/404.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100vw;
  @apply relative;
}

h1{
  @apply text-white;
  font-size: 20vw;
  margin-top: -3.5rem;
}
</style>
